import { useState } from "react";
import iCalendarPlugin from "@fullcalendar/icalendar";
import "./App.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import OpenAI from "openai";
import { Amplify } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Storage } from "@aws-amplify/storage";
import awsExports from "./aws-exports";
//import Coffee from "./Coffee";
const date = new Date();
const eventPrompt = `You will generate ICS files for users' events. Today is ${date}. If users don't provide a year for their event, use 2024.`;
const appendPrompt = `You will be provided an ICS file, and your task is to add and format that file's event details into an existing ICS file. Today is ${date}. If users don't provide a year for their event, use 2024.`;
// const calendarPrompt = `You will generate an entire ICS calendar file for users' events. Users will present you one or more events in a single input, and you must divide them into individual VEVENTs within the ICS file. Today is ${date}. If users don't provide a year for their event, use the year 2023.`;
var icsFile;
var icsFileContent;
var currentMessage;
const userID = uuidv4();

Amplify.configure(awsExports);

var previousICSFile;
var signedURL;

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_API_KEY,
  dangerouslyAllowBrowser: true,
});

export default function App() {
  const [loading, setLoading] = useState(false);
  const [navActive, setNavActive] = useState("Event");
  const [message, setMessage] = useState("");

  function preventEmptyMessage(e) {
    alert("You need to enter event details.");
    e.preventDefault();
  }

  const createICSFile = async (e, message) => {
    setLoading(true);
    e.preventDefault();

    if (!message) return;
    currentMessage = message;
    setMessage("");

    await openai.chat.completions
      .create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: eventPrompt,
          },
          {
            role: "user",
            content: message,
          },
        ],
        max_tokens: 1024,
      })
      .then(async (res) => {
        if (!res.choices[0].message.content.startsWith("BEG")) {
          setLoading(false);
          alert(
            "Sorry, we couldn't create an ICS file from your input. To potentially fix this: \n - try including a time (range) for your event. \n - refrain from profanity."
          );
          return;
        }

        // This also handles incoming data containing the "\n" line breaks.
        const file = new File(
          [res.choices[0].message.content],
          `${userID}-calendar.ics`,
          {
            type: "text/calendar",
          }
        );

        icsFileContent = res.choices[0].message.content;
        icsFile = file;

        await Storage.put(`${userID}-calendar.ics`, file, {
          level: "public",
          contentType: "text/calendar",
        });

        // get the signed URL string
        signedURL = await Storage.get(`${userID}-calendar.ics`, {
          level: "public",
          contentType: "text/calendar",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div className="flex fixed flex-col items-center justify-center w-screen h-screen">
        <article className="flex mx-auto prose sm:prose-sm">
          {navActive === "Event" ? (
            <h1>ICS File Creator</h1>
          ) : (
            <h1>ICS Calendar Generator (Beta)</h1>
          )}
        </article>

        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            iCalendarPlugin,
            //   multiMonthPlugin,
          ]}
          height={510}
          //  multiMonthMinWidth={200}
          eventSources={[
            {
              url: signedURL,
              format: "ics",
            },
          ]}
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          selectable={true}
          dayMaxEvents={true}
        />
        <div className="flex flex-col flex-grow w-full max-w-xl">
          <form
            onSubmit={(e) =>
              message === ""
                ? preventEmptyMessage(e)
                : createICSFile(e, message)
            }
          >
            <textarea
              className="flex w-full textarea textarea-bordered"
              rows={3}
              placeholder={
                navActive === "Event"
                  ? "Example Event: Doctor's appointment tomorrow at 2-3 p.m."
                  : "Example Events: Create a fitness calendar with two daily running sessions, one session from 8-10 a.m. and another session from 3-5 p.m."
              }
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoComplete="on"
              autoFocus
            />

            <button
              className="flex relative bottom-0 btn btn-block"
              type="submit"
            >
              {loading === true ? (
                <span className="loading loading-spinner"></span>
              ) : (
                "Create"
              )}
            </button>
          </form>
          <div className="join">
            <button
              className="flex flex-grow relative bottom-0 join-item btn"
              disabled={loading || !icsFileContent === true ? true : false}
              onClick={() => {
                window.location.reload();
                setLoading(false);
              }}
            >
              Clear
            </button>
            <button
              className="flex flex-grow relative bottom-0 join-item btn"
              disabled={!icsFile || loading === true ? true : false}
              onClick={() => {
                let link = document.createElement("a");
                let url = URL.createObjectURL(icsFile);

                link.href = url;
                link.download = icsFile.name;
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);
              }}
            >
              Download Calendar (ICS File)
            </button>
            {/*
            <button
              className="flex flex-grow join-item btn"
              disabled={!icsFile || loading === true ? true : false}
              onClick={async (currentMessage) => {
                setLoading(true);

                await openai.chat.completions
                  .create({
                    model: "gpt-3.5-turbo",
                    messages: [
                      {
                        role: "system",
                        content: eventPrompt,
                      },
                      {
                        role: "user",
                        content: currentMessage,
                      },
                    ],
                    max_tokens: 1024,
                  })
                  .then(async (res) => {
                    if (!res.choices[0].message.content.startsWith("BEG")) {
                      setLoading(false);
                      alert(
                        "Sorry, we couldn't create an ICS file from your input. To potentially fix this: \n - try including a time (range) for your event. \n - refrain from profanity."
                      );
                      return;
                    }

                    // This also handles incoming data containing the "\n" line breaks.
                    const file = new File(
                      [res.choices[0].message.content],
                      `${userID}-calendar.ics`,
                      {
                        type: "text/calendar",
                      }
                    );

                    icsFileContent = res.choices[0].message.content;
                    icsFile = file;

                    await Storage.put(`${userID}-calendar.ics`, file, {
                      level: "public",
                      contentType: "text/calendar",
                    });

                    // get the signed URL string
                    signedURL = await Storage.get(`${userID}-calendar.ics`, {
                      level: "public",
                      contentType: "text/calendar",
                    });
                    setLoading(false);
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.log(error);
                  });
              }}
            >
              {" "}
              {loading === true ? (
                <span className="loading loading-spinner"></span>
              ) : (
                "Retry"
              )}
              </button>*/}
          </div>
        </div>
        {/*<div>
          <code>{icsFileContent}</code>
          </div>*/}
        {/* <div className="flex relative btm-nav">
        <button
          onClick={() => {
            setNavActive("Event");
          }}
          className={navActive === "Event" ? "active" : ""}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="5" width="18" height="14" rx="2" ry="2" />
            <line x1="3" y1="10" x2="21" y2="10" />
          </svg>

          <span className="btm-nav-label">Event</span>
        </button>
        <button
          onClick={() => {
            setNavActive("Calendar");
          }}
          className={navActive === "Calendar" ? "active" : ""}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
            <line x1="16" y1="2" x2="16" y2="6" />
            <line x1="8" y1="2" x2="8" y2="6" />
            <line x1="3" y1="10" x2="21" y2="10" />
          </svg>
          <span className="btm-nav-label">Calendar (Beta)</span>
        </button>
      </div>*/}
        {/* <Coffee />*/}
      </div>
    </>
  );
}
